import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import theme from '../../components/theme';
// @ts-ignore
import Cookies from 'js-cookie';
import useApplicationStore from '@services/stores/ApplicationStore';
import ProductNotify from '@components/misc/notifications/ProductNotify';
import StorefrontApiService from '@services/StorefrontApiService';
import MyNewportService, { AuthResponse, CreateWishlist, WishlistResourceModel } from '../MyNewportService';
import CallToActionButton from '@components/products/partials/CallToActionButton';
import { useTranslation } from '../../tools/i18n';

export interface MyNewportUser {
    id: number;
    voyado_id: string;
    email_verified_at: string | Date;
    country_code: string;
    needs_email: false;
    member_number: string;
    name: string | null;
    first_name: string;
    last_name: string;
    website: string;
    email: string;
    token: string;
    voyado?: VoyadoAttributes;
}

export interface VoyadoAttributes {
    discovery_key: string
}

export interface Wishlist extends WishlistResourceModel {}

export interface WishlistMethods {
    createNewWishlist: (params: CreateWishlist) => Promise<any>;
    existsInWishlist: (productId: string, wishlistId: number) => boolean;
    toggleWishlist: (productId: string, wishlistId: number) => Promise<void>;
    addToWishlist: (productId: string, wishlistId: number) => Promise<void>;
    removeFromWishlist: (productId: string, wishlistId: number) => Promise<void>;
}

export interface WeddingMethods {
    getWeddingList: () => Wishlist | null;
    toggleWeddingList: (productId: string) => Promise<void>;
    hasWeddingList: () => boolean;
    existsInWeddingList: (productId: string) => boolean;
}

export interface FavoriteMethods {
    moveToList: (productIds: string[], wishlistId:number) => Promise<boolean>;
    addToFavorites: (productId: string) => void;
    removeFromFavorites: (productId: string) => void;
    clearFavorites: () => void;
    existsInFavorites: (productId: string) => boolean;
}

export interface UserState {
    isLoggedIn: boolean;
    config: any;
    user: MyNewportUser | null;
    favorites: string[];
    theme: string;

    favoriteProducts: string[];
    wishlists: Wishlist[];
    wishlistProducts: { product_id: string; wishlist_id: number }[];
    weddingMethods: WeddingMethods;
    wishlistMethods: WishlistMethods;
    favoriteMethods: FavoriteMethods;

    service: MyNewportService | null;
    setConfig: (config: any) => void;
    isHighlighted: (productId: string) => boolean;
    refreshState: () => Promise<void>;

    init: () => Promise<void>;
    logout: (callback?: () => void) => Promise<void>;
    login: (user: MyNewportUser, auth?: AuthResponse) => void;

    clearLoggedInData: () => void;
    checkLoginStatus: () => boolean;
    getService: () => MyNewportService;
    toggleWishlistDrawer: (productId: string, disableToaster?: boolean) => void;
    addFavorite: (productId: string) => Promise<void>;
    removeFavorite: (productId: string) => Promise<void>;

    updateAccount: (user: MyNewportUser) => Promise<void>;
}

const COOKIE_NAME = process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN || 'my_newport_token';

const useUserStore = create<UserState>()(
    persist(
        (set, get) => ({
            isLoggedIn: false,
            config: typeof window !== 'undefined' ? window?.__NEXT_DATA__?.props?.publicConfig : null,
            user: null,
            favorites: [],
            theme: theme.name,
            favoriteProducts: [],
            wishlists: [], // reset on logout
            wishlistProducts: [], // reset on logout
            weddingMethods: {
                async toggleWeddingList(productId) {
                    const state = get();
                    const wishlist = state.wishlists.find((item) => item.type === 'wedding');
                    const wishlistId = wishlist?.id;

                    if (!wishlistId) {
                        return;
                    }

                    if (state.weddingMethods.existsInWeddingList(productId)) {
                        await state.wishlistMethods.removeFromWishlist(productId, wishlistId);
                    } else {
                        await state.wishlistMethods.addToWishlist(productId, wishlistId);
                    }

                    state.refreshState().then(() => null);
                },
                getWeddingList() {
                    return get().wishlists.find((item) => item.type === 'wedding') || null;
                },
                hasWeddingList() {
                    return !!get().weddingMethods.getWeddingList();
                },
                existsInWeddingList(productId) {
                    const weddingList = get().weddingMethods.getWeddingList();
                    console.log(weddingList);

                    if (!weddingList) {
                        return false;
                    }

                    return get().wishlistMethods.existsInWishlist(productId, weddingList.id);
                },
            },
            wishlistMethods: {
                async createNewWishlist(params: CreateWishlist) {
                    const app = useApplicationStore.getState()?.methods;

                    let response = await get().getService().createWishlist(params);

                    if (response.status === 'success') {
                        let newWishlists = [ ...get().wishlists ];
                        newWishlists.push(response.data);
                        set({ wishlists: newWishlists });
                    } else {
                        app.notify('failed-creating-wishlist');
                    }

                    return response;
                },
                existsInWishlist(productId: string, wishlistId: number) {
                    const wishlistProducts = get().wishlistProducts;

                    // @ts-ignore @todo
                    return wishlistProducts.filter((item) => item.product_id === productId && item.wishlist_id === wishlistId).length > 0;
                },
                async toggleWishlist(productId: string, wishlistId: number) {
                    const wishlistMethods = get().wishlistMethods;
                    if (wishlistMethods.existsInWishlist(productId, wishlistId)) {
                        await wishlistMethods.removeFromWishlist(productId, wishlistId || get().wishlists[0].id);
                    } else {
                        await wishlistMethods.addToWishlist(productId, wishlistId || get().wishlists[0].id);
                    }
                    get().refreshState().then(() => null);
                },
                async addToWishlist(productId, wishlistId) {
                    const app = useApplicationStore.getState()?.methods;
                    const service = get().getService();
                    const updatedWishlist = await service.addWishlistProduct(wishlistId, productId);

                    if (updatedWishlist.status === 'success') {
                        const newWishlists = get().wishlists.map((wishlist) => {
                            if (updatedWishlist.data.id === wishlist.id) {
                                wishlist = updatedWishlist.data;
                            }

                            return wishlist;
                        });

                        set({ wishlists: newWishlists });
                    } else {
                        app.notify('wishlist-error');
                    }
                },
                async removeFromWishlist(productId, wishlistId) {
                    const app = useApplicationStore.getState()?.methods;
                    const service = get().getService();
                    const updatedWishlist = await service.removeWishlistProduct(wishlistId, productId);

                    if (updatedWishlist.status === 'success') {
                        const newWishlists = get().wishlists.map((wishlist) => {
                            if (updatedWishlist.data.id === wishlist.id) {
                                wishlist = updatedWishlist.data;
                            }

                            return wishlist;
                        });

                        set({ wishlists: newWishlists });
                    } else {
                        app.notify('wishlist-error');
                    }
                },
            },
            favoriteMethods: {
                async moveToList(productIds: string[], wishlistId: number) {
                    let counter = 0;

                    if (!Array.isArray(productIds)) {
                        return false;
                    }

                    if (!wishlistId) {
                        return false;
                    }

                    try {
                        await Promise.all(productIds?.filter((item) => {
                            return !get().wishlistMethods.existsInWishlist(item, wishlistId);
                        }).map(async (item) => {
                            counter++;
                            return get().wishlistMethods.addToWishlist(item, wishlistId);
                        }));

                        if (productIds?.length === counter) {
                            console.log('productIds', counter);
                        }

                        return true;
                    } catch (e) {
                        console.error(e);
                        return false;
                    }
                },
                addToFavorites(productId) {
                    if (typeof productId !== 'string') {
                        console.error('id is not a string');
                        return;
                    }

                    const state = get();
                    const favorites = new Set(state.favorites);
                    favorites.add(productId);
                    set({ favorites: [ ...favorites ] });
                },
                removeFromFavorites(productId) {
                    const state = get();
                    const favorites = state.favorites.filter((item) => {
                        return item !== productId;
                    });
                    set({ favorites });
                },
                clearFavorites() {
                    set((state) => {
                        return { favorites: [] };
                    });
                },
                existsInFavorites(productId) {
                    const favorites = get().favorites;
                    return favorites.filter((item) => item === productId).length > 0;
                },
            },
            service: null,
            setConfig(config) {
                set({ config });
            },
            isHighlighted(productId) {
                return get().favorites.includes(productId);
            },
            async refreshState() {
                const state = get();
                const service = state.getService();
                const wishlists = await service.getWishlists();
                const products = await service.getWishlistProductIds();
                const isLoggedIn = state.checkLoginStatus();

                set({ isLoggedIn });

                if (!isLoggedIn) {
                    get().clearLoggedInData();
                    return;
                }

                if (wishlists.status === 'success') {
                    set({ wishlists: wishlists.data });
                }

                if (Array.isArray(products)) {
                    set({ wishlistProducts: products });
                }
            },
            async init() {
                try {
                    const state = get();
                    const service = state.getService();
                    const isLoggedIn = state.isLoggedIn;

                    if (isLoggedIn) {
                        const response = await service.getAccountUserData();
                        const user = response.data;
                        if (user) {
                            set({ user });
                            await state.updateAccount(user);
                        }
                    }
                } catch (e) {
                    console.error(e);
                }

                await get().refreshState();
            },
            async logout(callback) {
                get().getService().logout().then(r => null);
                Cookies.remove(COOKIE_NAME);
                Cookies.remove(COOKIE_NAME, {
                    domain: typeof location === 'object' ? location.host.substring(location.host.indexOf('.')).split(':')?.[0] || undefined : undefined,
                });
                get().clearLoggedInData();
                if (callback) {
                    callback();
                }
            },
            login(user: MyNewportUser, auth) {
                if (location?.host.includes('localhost') && auth?.token) {
                    Cookies.set('my_newport_token', auth?.token || 'failed', {
                        expires: 1337,
                        domain: location?.hostname
                    });
                }

                dispatchEvent(new CustomEvent('user-logged-in'))

                set({ user, isLoggedIn: true, });
            },
            clearLoggedInData() {
                set({
                    isLoggedIn: false,
                    user: null,
                    wishlists: [],
                    wishlistProducts: [],
                });
                localStorage.removeItem('apptus.session');
            },
            checkLoginStatus() {
                const isLoggedIn = !!Cookies.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN || '');

                if (!isLoggedIn) {
                    get().clearLoggedInData();
                }

                if (get().isLoggedIn !== isLoggedIn) {
                    set({ isLoggedIn: isLoggedIn });
                }

                return isLoggedIn;
            },
            getService(): MyNewportService {
                const state = get();

                if (state.config.myNewport && !state.service) {
                    const service = new MyNewportService({
                        basePath: state.config.myNewport,
                        theme: state.theme,
                        isLoggedIn: state.isLoggedIn,
                        userMethods: state
                    });
                    set({ service });
                    return service;
                }

                if (state.service) {
                    state.service.isLoggedIn = state.isLoggedIn;
                }

                // @ts-ignore
                return state.service;
            },
            toggleWishlistDrawer(productId: string, disableToaster: boolean = false) {
                window.dispatchEvent(new CustomEvent('toggle-wishlist-product', { detail: { productId: productId, disableToaster: disableToaster } }));
            },
            async addFavorite(productId) {
                const isLoggedIn = get().isLoggedIn;

                if (!isLoggedIn && !get().isHighlighted(productId)) {
                    get().favoriteMethods.addToFavorites(productId);

                    const api = new StorefrontApiService();
                    const response = await api.getProductByIds([ productId ]);
                    if (response.status === 'success') {
                        const app = useApplicationStore.getState()?.methods;
                        const product = (response.products.find((item: { id: string; }) => item.id === productId));
                        // @ts-ignore
                        const productElement = <ProductNotify product={product}></ProductNotify>;
                        app.notify(null, {
                            title: 'favorites-add-product',
                            id: productId,
                            components: productElement,
                            onClick: () => {
                                app?.toggleDrawer('wishlistDrawerOpen');
                                app?.removeNotification(productId);
                            },
                        });
                    }
                }

                if (isLoggedIn) {
                    get().favoriteMethods.addToFavorites(productId);
                    get().toggleWishlistDrawer(productId);
                }
            },
            async removeFavorite(productId) {
                get().favoriteMethods.removeFromFavorites(productId);
            },
            async updateAccount(user) {
                const discoveryKey = user?.voyado?.discovery_key;
                const localDiscoveryKey = get().getService().getLocalDiscoveryKey();

                if (
                    get().isLoggedIn
                    && user
                    && typeof discoveryKey !== 'string'
                    && user.voyado_id
                ) {
                    if (discoveryKey) {
                        await get().getService().updateDiscoveryKey(localDiscoveryKey);
                    }
                }

                if (user && get().isLoggedIn && user.voyado_id) {
                    if (typeof discoveryKey === 'string' && localDiscoveryKey !== discoveryKey) {
                        get().getService().setLocalDiscoveryKey(discoveryKey);
                    }
                }
            },
        }),
        {
            name: 'user-storage',
            partialize: (state) => ({
                user: state.user,
                favorites: state.favorites,
                wishlistProducts: state.wishlistProducts,
                wishlists: state.wishlists,
            }),
        },
    ),
);

export default useUserStore;
