import Cookie from 'js-cookie';
import theme from '../components/theme';
import { MyNewportUser, UserState } from '@services/stores/UserStore';
import { ShopwareProduct, ShopwareUuid } from '@services/types/Shopware';
import { OrderHistoryItem, OrderModel } from '@services/types/MyNewportOrderTypes';

/** Api Docs Reference
 POST       api/account/discovery-key ...................................... Api\Member\DiscoveryKeyController
 POST       api/account/order ..................................... Api\Member\OrderHistoryController@getOrder
 POST       api/account/order/history .............................. Api\Member\OrderHistoryController@history
 GET|HEAD   api/admin/customer-service/contact/{contactUuid} .. Api\Admin\CustomerService\GetContactController
 DELETE     api/admin/customer-service/contact/{contactUuid} Api\Admin\CustomerService\DeleteContactController
 POST       api/admin/customer-service/contact/{contactUuid}/trigger-update Api\Admin\CustomerService\Trigger…
 POST       api/admin/statistics/users ......................... Api\Admin\Statistics\UserStatisticsController
 POST       api/admin/statistics/wishlist ....................... Api\Admin\Statistics\WishlistStatsController
 GET|HEAD   api/auth/account .................................................. Api\Auth\UserAccountController
 GET|HEAD   api/auth/bankid/authenticate .............................. Api\Auth\BankIDController@authenticate
 GET|HEAD   api/auth/bankid/status/{orderRef} ............................... Api\Auth\BankIDController@status
 POST       api/auth/forgot-password ............................... Api\Auth\ForgotPasswordController@execute
 POST       api/auth/login .............................................. Api\Auth\LoginLogoutController@login
 POST       api/auth/logout ............................................ Api\Auth\LoginLogoutController@logout
 POST       api/auth/register ............................................ Api\Auth\RegisterController@execute
 POST       api/auth/reset-password ................ password.reset › Api\Auth\ResetPasswordController@execute
 POST       api/auth/send-verification-email ................ Api\Auth\SendVerificationEmailController@execute
 POST       api/auth/set-email ........................................... Api\Auth\SetEmailController@execute
 GET|HEAD   api/auth/verify-email/{id}/{hash} ... verification.verify › Api\Auth\VerifyEmailController@execute
 GET|HEAD   api/content/campaigns .............................. Api\Storefront\NavigationController@campaigns
 POST       api/fourofour ................................................... Api\RedirectsController@checkUrl
 POST       api/member/invitation/signup .............................. Api\Member\InvitationController@signup
 POST       api/member/product/subscribe .................... Api\Member\ProductSubscriberController@subscribe
 POST       api/member/promotion/validate ....................... Api\Member\PromotionsController@validateCode
 POST       api/member/register ....................................... Api\Member\RegisterController@register
 GET|HEAD   api/member/v/{id} ............................................ Api\Member\EditController@getMember
 POST       api/member/v/{id}/pause-email/{messageId?} .................. Api\Member\EditController@pauseEmail
 POST       api/member/v/{id}/unsubscribe-email/feedback ....... Api\Member\EditController@unsubscribeFeedback
 POST       api/member/v/{id}/unsubscribe-email/{messageId?} ...... Api\Member\EditController@unsubscribeEmail
 POST       api/member/v/{id}/{form} .................................. Api\Member\EditController@updateMember
 POST       api/newsletter/subscribe ............................ Api\Newsletter\SubscribeController@subscribe
 POST       api/product-sample/order ............................ Api\ProductSampleOrderController@createOrder
 GET|HEAD   api/product-sample/order/list ........................ Api\ProductSampleOrderController@listOrders
 GET|HEAD   api/product-sample/order/list/unfetched ..... Api\ProductSampleOrderController@listUnfetchedOrders
 PATCH      api/product-sample/order/{orderId}/mark-as-fetched Api\ProductSampleOrderController@markOrdersAsF…
 POST       api/rma/create ............................................. Api\ReturnsController@createComplaint
 POST       api/rma/retrieve-order ........................................ Api\ReturnsController@getOrderData
 POST       api/shipping/service-points ..................... Api\Shipping\ServicePoints\FindNearestController
 GET|HEAD   api/store/countries ............................................. Api\Storefront\CountryController
 GET|HEAD   api/store/navigation/footer ........................... Api\Storefront\NavigationController@footer
 GET|HEAD   api/store/navigation/main ............................... Api\Storefront\NavigationController@main
 GET|HEAD   api/user .........................................................................................
 GET|HEAD   api/wishlist ................................................. Api\Wishlist\ListController@execute
 POST       api/wishlist ............................................... Api\Wishlist\CreateController@execute
 GET|HEAD   api/wishlist/product-ids ................................ Api\Wishlist\HighlightProductsController
 GET|HEAD   api/wishlist/public/{uuid} ................................... Api\Wishlist\ViewController@execute
 POST       api/wishlist/public/{uuid}/item/{productId}/purchase Api\Wishlist\ItemPurchaseAddController@execu…
 GET|HEAD   api/wishlist/search/{searchTerm} ........................... Api\Wishlist\SearchController@execute
 GET|HEAD   api/wishlist/wedding-list ............................. Api\Wishlist\ViewController@getWeddingList
 GET|HEAD   api/wishlist/{id} ............................................ Api\Wishlist\ViewController@execute
 PATCH      api/wishlist/{id} ............................................ Api\Wishlist\EditController@execute
 DELETE     api/wishlist/{id} .......................................... Api\Wishlist\DeleteController@execute
 POST       api/wishlist/{id}/item .................................... Api\Wishlist\ItemAddController@execute
 PATCH      api/wishlist/{id}/item/{productId} ....................... Api\Wishlist\ItemEditController@execute
 DELETE     api/wishlist/{id}/item/{productId} ..................... Api\Wishlist\ItemDeleteController@execute
 POST       api/wishlist/{id}/item/{productId}/purchase ....... Api\Wishlist\ItemPurchaseAddController@execute
 DELETE     api/wishlist/{id}/item/{productId}/purchase/{purchaseItemId} Api\Wishlist\ItemPurchaseDeleteContr…
 POST       api/wishlist/{id}/share-email .................................. Api\Wishlist\ShareEmailController
 GET|HEAD   deliverytracker/{shipmentId}/{partner?} ................ ParcelTrackerController@redirectToPartner
 GET|HEAD   images/product/{uuid}/{imageId}/{imageFormat} ....................... Api\ImagesController@execute
 GET|HEAD   images/product/{uuid}/{imageId}/{imageFormat}/{filename} ............ Api\ImagesController@execute
 GET|HEAD   images/user/image/{emailHash}.{extension?} ................ Api\UserInfoController@streamUserImage
 GET|HEAD   images/user/signature/{emailHash}.{extension?} ........ Api\UserInfoController@streamUserSignature
 GET|HEAD   login{spa?} ............................................... login › Frontend\SpaController@execute
 GET|HEAD   mail-online/premium-delivery/{orderId}/{mode} . Api\MailPreviewerController@previewPremiumDelivery
 GET|HEAD   returns{spa} ...................................................... Frontend\SpaController@execute
 GET|HEAD   sanctum/csrf-cookie ............ sanctum.csrf-cookie › Laravel\Sanctum › CsrfCookieController@show
 GET|HEAD   u/unsubscribe{shortMemberId?} ...................... Api\Member\EditController@redirectUnsubscribe
 GET|HEAD   u{spa?} ........................................................... Frontend\SpaController@execute
 GET|HEAD   version ..........................................................................................
 GET|HEAD   wishlist{spa?} ......................................... wishlist › Frontend\SpaController@execute
 ANY        {catchall} .............................................. DefaultFallbackController@handleFallback
 */

const COOKIE_NAME: string = process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN || 'my_newport_token';

export interface MyNewportConfig {
    userMethods: UserState;
    basePath: string;
    theme: string;
    isLoggedIn: boolean;
}

export interface MyNewportResponse<T> {
    status: 'success' | 'error' | 'not-logged-in';
    data: T;
    response?: Response;
}

export interface WishlistProductId {
    product_id: ShopwareUuid,
    wishlist_id: number
}

interface WishlistProductIdsResponse extends MyNewportResponse<any> {
    ids: Array<WishlistProductId>;
}

interface LoggedOutResponse {
    status: 'not-logged-in';
    data: null;
}

export interface AuthResponse {
    cookie: string;
    expires: string | Date;
    max_age: number;
    token: string;
}

export interface LoginResponse {
    auth: AuthResponse,
    user: MyNewportUser,
}

export interface ErrorResponse {
    message: string;
    status: 'critical' | 'error';
    data?: any;
    ids?: Array<string>;
}

export type WishlistType = 'wedding' | 'standard' | 'christmas';

export interface CreateWishlist {
    name: string;
    type: WishlistType;
    text: string;
    location: string;
    date: string;
}

export interface WishlistUser {
    id: number;
    first_name: string;
    last_name: string;
    vid: string;
}

export interface WishlistItem {
    id: number;
    product_id: string;
    product: ShopwareProduct; // Adjust if you have a defined product structure
    purchased_qty: number;
    qty: number;
    remaining_qty: number;
    updated_at: string | Date;
}


export interface WishlistResourceModel {
    id: number;
    uuid: string;
    user: WishlistUser;
    name: string;
    public: boolean;
    created_at: string; // Consider Date if using Date objects
    updated_at: string; // Consider Date if using Date objects
    type: 'wedding' | 'standard' | 'christmas';
    location?: string | null;
    text?: string | null;
    date?: string | null; // Assuming 'Y-m-d' format as string
    items?: WishlistItem[];
    items_count?: number | null;
    meta?: Record<string, any>; // Assuming meta is a flexible object
}

export interface WishlistUpdateRequest {
    name?: string;
    public?: boolean;
    date?: string | Date | null;
    type?: WishlistType,
    text?: string | null;
    location?: string | null;
    meta?: {
        person1?: {
            firstName?: string;
            lastName?: string;
        },
        person2?: {
            firstName?: string;
            lastName?: string;
        },
    },
}

export interface WishlistPublicData extends WishlistResourceModel {}

export interface VoyadoMember {
    short_member_id: string;
    id: string;
    first_name: string | null;
    last_name: string | null;
    street: string | null;
    care_of: string | null;
    city: string | null;
    zip_code: string | null;
    email: string | null;
    mobile_phone: string | null;
    social_security_number: string | null;
    email_frequency: number;
    contact_preferences_accepts_email: boolean;
    contact_preferences_accepts_sms: boolean;
}

class MyNewportService {
    theme: string;
    isLoggedIn: boolean;
    userMethods: UserState;
    basePath: string;

    constructor(MyNewportConfig: MyNewportConfig) {
        let themeConfig = theme.name;
        if (themeConfig === 'xmas') {
            themeConfig = 'christmas';
        }

        this.theme = themeConfig;
        this.isLoggedIn = MyNewportConfig.isLoggedIn;
        this.userMethods = MyNewportConfig?.userMethods;

        this.getCsrfToken();

        if (MyNewportConfig?.basePath?.endsWith('/')) {
            this.basePath = MyNewportConfig.basePath.slice(0, 1);
        } else {
            this.basePath = MyNewportConfig?.basePath;
        }
    }

    getToken() {
        if (typeof Cookie.get(COOKIE_NAME) === 'string') {
            return atob(<string>Cookie.get(COOKIE_NAME));
        } else {
            return false;
        }
    }

    getHeaders() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.getToken()}`,
        };
    }

    getOrders(page: number = 1, perPage: number = 50): Promise<MyNewportResponse<OrderHistoryItem[]>> {
        return this.post('account/order/history', {
            per_page: perPage,
            page: page,
        });
    }

    getOrder(id: number): Promise<MyNewportResponse<OrderModel>> {
        return this.post('account/order', {
            order_id: id,
        });
    }

    getApptusSession() {
        return localStorage.getItem('apptus.session') ? JSON.parse(<string>localStorage.getItem('apptus.session')) : null;
    }

    getLocalDiscoveryKey() {
        return localStorage.getItem('apptus.session') ? JSON.parse(<string>localStorage.getItem('apptus.session')).customerKey : false;
    }

    setLocalDiscoveryKey(discoveryKey: string) {
        const apptus = this.getApptusSession();

        if (apptus) {
            apptus.customerKey = discoveryKey;
            localStorage.setItem('apptus.session', JSON.stringify(apptus));
        }
    }

    apiPath(path: string) {
        return `${this.basePath}/api/${path}`;
    }

    logout() {
        return this.post('auth/logout');
    }

    createLink(path: string) {
        const cleaned = path.split('/').filter((item) => item.length).join('/');
        return `${this.basePath}/${cleaned}`;
    }

    async handleResponse(response: Response | LoggedOutResponse): Promise<MyNewportResponse<any> | MyNewportResponse<ErrorResponse>> {
        if (response.status === 'not-logged-in') {
            return response;
        }

        if (
            response.status === 401
            || response.status === 403
        ) {
            this.userMethods.logout().then();
            return { status: 'not-logged-in', data: null };
        }

        if (response.status >= 400) {
            return {
                status: 'error',
                data: null,
                response,
            };
        }

        const data = await response.json();

        if (data.data) {
            return data;
        }

        return {
            data,
            status: 'success',
            response,
        };
    }

    async getCsrfToken() {
        return this.get('/sanctum/csrf-cookie');
    }

    async startBankIdSession() {
        return this.get('auth/bankid/authenticate', true);
    }

    async login(email: string, password: string): Promise<MyNewportResponse<LoginResponse>> {
        return this.post('auth/login', {
            email,
            password,
        }, true);
    }


    async forgotPassword(email: string) {
        return this.post('auth/forgot-password', {
            email: email,
        }, true);
    }

    async registerNewAccount(registration: {
        first_name: string,
        last_name: string,
        country_code: string,
        website: string,
        email: string,
        password: string,
        password_confirmation: string,
    }) {
        return this.post('auth/register', registration, true);
    }

    async fetchBankIdSessionStatus(orderReferenceId: string) {
        return this.get(`auth/bankid/status/${orderReferenceId}`, true);
    }

    async get(path: string, byPass = false): Promise<MyNewportResponse<any>> {
        if (!this.isLoggedIn && !byPass) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'GET',
            credentials: 'include',
            headers: this.getHeaders(),
        });

        return this.handleResponse(response);
    }

    async delete(path: string): Promise<MyNewportResponse<any>> {
        if (!this.isLoggedIn) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'DELETE',
            credentials: 'include',
            headers: this.getHeaders(),
        });

        return this.handleResponse(response);
    }

    async post(path: string, data?: any | undefined, byPass: boolean = false): Promise<MyNewportResponse<any>> {
        if (!this.isLoggedIn && !byPass) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'POST',
            credentials: 'include',
            headers: this.getHeaders(),
            body: JSON.stringify(data),
        });

        return this.handleResponse(response);
    }

    async patch(path: string, data?: any | undefined, byPass: boolean = false): Promise<MyNewportResponse<any>> {
        if (!this.isLoggedIn && !byPass) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'PATCH',
            credentials: 'include',
            headers: this.getHeaders(),
            body: JSON.stringify(data),
        });

        return this.handleResponse(response);
    }

    async getWishlists(): Promise<MyNewportResponse<WishlistResourceModel[]> | ErrorResponse> {
        try {
            let responseJson = await this.get('wishlist');

            if (responseJson.status !== 'success') {
                throw new Error('Wishlist failed:  getWishlists');
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getWishlist(
        wishlistUuid: string | number,
    ): Promise<MyNewportResponse<WishlistResourceModel> | ErrorResponse> {

        try {
            let responseJson = await this.get(`wishlist/${wishlistUuid}`);

            if (responseJson.status !== 'success') {
                throw new Error('Wishlist failed:  getWishlist');
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async updateWishlist(id: number, payload: WishlistUpdateRequest): Promise<MyNewportResponse<WishlistResourceModel>> {
        return this.patch(`wishlist/${id}`, payload);
    }

    async getWeddingList(): Promise<MyNewportResponse<WishlistResourceModel> | ErrorResponse> {
        try {
            let responseJson = await this.get('wishlist/wedding-list');

            if (!responseJson.data) {
                throw new Error('Wishlist failed:  getWeddingList');
            }

            return { status: 'success', data: responseJson.data };
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getPublicWishlistProducts(
        wishlistId: string,
    ): Promise<MyNewportResponse<WishlistPublicData> | ErrorResponse> {
        try {
            let responseJson = await this.get(`wishlist/public/${wishlistId}`, true);

            if (responseJson.status === 'error') {
                throw new Error('Wishlist failed:  getPublicWishlistProducts');
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getWishlistProductIds(): Promise<Array<WishlistProductId> | ErrorResponse> {
        try {
            let responseJson = <WishlistProductIdsResponse>await this.get('wishlist/product-ids');

            if (responseJson.status === 'error') {
                return [];
            }

            return responseJson?.data?.ids || [];
        } catch (e) {
            return this.handleError(e);
        }
    }

    async addWishlistProduct(
        wishlistId: number,
        productId: ShopwareUuid,
    ): Promise<MyNewportResponse<WishlistResourceModel> | ErrorResponse> {
        try {
            let responseJson = await this.post(`wishlist/${wishlistId}/item`, {
                product_id: productId,
                qty: 1,
            });

            if (responseJson.status !== 'success') {
                throw new Error('Wishlist failed:  addWishlistProduct');
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async editWishlistProduct(
        wishlistId: string,
        productId: ShopwareUuid,
        qty: number,
    ): Promise<MyNewportResponse<WishlistResourceModel> | ErrorResponse> {
        try {
            let responseJson = await this.patch(`wishlist/${wishlistId}/item`, {
                product_id: productId,
                qty: qty,
            });

            if (responseJson.status !== 'success') {
                throw new Error('Wishlist failed:  editWishlistProduct');
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async removeWishlistProduct(
        wishlistId: number,
        productId: ShopwareUuid,
    ): Promise<MyNewportResponse<WishlistResourceModel> | ErrorResponse> {
        try {
            let responseJson = await this.delete(`wishlist/${wishlistId}/item/${productId}`);

            if (responseJson.status !== 'success') {
                throw new Error('Wishlist failed:  removeWishlistProduct');
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async createWishlist(
        {
            name,
            type,
            text,
            location,
            date,
        }: CreateWishlist): Promise<MyNewportResponse<WishlistResourceModel> | ErrorResponse> {
        const payload = {
            name,
            type,
            ...(text && { text }),
            ...(location && { location }),
            ...(date && { date }),
        };

        try {
            let responseJson = await this.post('wishlist', payload);

            if (responseJson.status !== 'success') {
                throw new Error('Wishlist failed:  createWishlist');
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getAccountUserData(): Promise<MyNewportResponse<MyNewportUser>> {
        return this.get('auth/account');
    }

    async getEditableAccountData(uuid: string): Promise<MyNewportResponse<VoyadoMember>> {
        return this.get(`member/v/${uuid}`);
    }

    async updateDiscoveryKey(discoveryKey: string) {
        try {
            let response = await this.post('account/discovery-key', {
                discovery_key: discoveryKey,
            });

            if (response.status === 'error') {
                console.error(`Couldn't update discovery key. [${response.status}]`);
            }
        } catch (e) {
            console.error('Couldn\'t update discovery key');
        }
    }

    handleError(e: Error | any): ErrorResponse {
        if (e) {
            console.error('[Wishlist]', e);
        } else {
            throw new Error('UnknownWishlistError');
        }

        return {
            status: 'critical',
            data: null,
            message: 'Failed fetching data',
        };
    }
}

export default MyNewportService;
