import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import StorefrontApiService from '@services/StorefrontApiService';
// @ts-ignore
import fetch from 'react-storefront/fetch';
// @ts-ignore
import debounce from 'lodash/debounce';
import SessionStore from './SessionStore';
import ApplicationStore from './ApplicationStore';
import { Cart, LineItem, ShippingMethod, ShopwareCart, ShopwareUuid } from '@services/types/Shopware';

const expressShippingSkus = {
    '9941': '3b41d892fd584e4b9c2259ba40922328',
    '9942': '36663c2aac7c4896acdccc02a9ffddcc',
};

const handleErrors = (result: any, type: string, message: string) => {
    console.error(result);
    return `${message} [${type}]`;
};

function debugLog(...args: any[]) {
    if (location?.href?.includes('localhost')) {
        console.log(...args);
    }
}

export interface CartStore {
    shipping: any;
    loadingShipping: boolean;
    loading: boolean;
    token: string | null;
    cart: Cart;
    specials: {
        preparedCoupon: string | null,
        shippingMethodId: ShopwareUuid | null,
    };
    shippingMethods: Array<Object> | null;
    methods: CartMethods;
}

interface CartHttpHeaders {
    'Content-Type': string;
    'Cache-Control': string;
    Pragma: string;
    Expires: string;
    [key: string]: string | number | boolean | undefined; // Allow additional properties
}

interface CartMethods {
    getCurrentShippingMethod(): ShippingMethod | null;

    getCart(isCheckout?: boolean): Promise<void>;

    updateCart(params: { item: any, quantity: number, isCheckout?: boolean }): Promise<void>;

    setCart(cart: Cart): void;

    setLoadingShipping(loading: boolean): void;

    autoApplyPreparedCoupon(): void;

    getPreparedCoupon(): string | null;

    setPreparedCoupon(coupon: string | null): void;

    setToken(token: string): void;

    refresh(): void;

    setIsLoading(bool: boolean, logMessage?: string): void;

    applyCoupon(code: string, email: string | null): Promise<Cart>;

    getAddedProductNumber(productId: ShopwareUuid): string | number;

    getAppliedCouponCodes(): Array<string>;

    addToCart(params: { product: any, quantity: number }): Promise<void>;

    referenceIdExistInCart(referenceId: ShopwareUuid | string): boolean;

    getCartDiscount(): number;

    getCartQuantity(): number;

    getShippingMethods(): Promise<void>;

    applyGiftcard(code: string): Promise<Cart>;

    setLoading(bool: boolean): void;

    trackAddedCouponCodes(cart: Cart): Promise<void>;

    removeGiftcard(code: string): void;

    getItemInCart(productId: string): LineItem | null;

    addItemsToCart(productIds: Array<string>): Promise<void>;

    removeCartItem(params: { item: LineItem, isCheckout?: boolean, code?: string }): Promise<void>;

    setShippingMethod(shippingMethodId: ShopwareUuid): void;
}

const useCartStore = create<CartStore>()(
    persist(
        (set, get): CartStore => {
            const setShippingMethodDebounced = debounce(async (shippingMethodId: string) => {
                const { setLoadingShipping } = get().methods;
                let getSetShippingMethodsEndpoint = '/api/checkout/set-shipping-method';

                setLoadingShipping(true);

                try {
                    let requestData = await fetch(getSetShippingMethodsEndpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            shippingMethodId,
                        }),
                    });

                    let shippingMethodsData = await requestData.json();

                    if (shippingMethodsData.status !== 'success') {
                        debugLog('Set shipping method error');
                    }

                    if (shippingMethodsData.status === 'success') {
                        debugLog('Set shipping method success');
                        await get().methods.getCart();
                    }

                    setLoadingShipping(false);
                } catch (e) {
                    console.error(e);
                    setLoadingShipping(false);
                }
            }, 100);

            // @ts-ignore
            return ({
                loadingShipping: false,
                loading: false,
                token: null,
                cart: {
                    extensions: {
                        'cart-promotions': {
                            addedCodes: [],
                            blockedPromotionIds: [],
                        },
                    },
                    lineItems: [],
                    errors: {},
                    price: {
                        totalPrice: 0,
                        apiAlias: 'calculated_price',
                        calculatedTaxes: [],
                        hasRange: false,
                        listPrice: null,
                        netPrice: 0,
                        positionPrice: 0,
                        quantity: 0,
                        rawTotal: 0,
                        referencePrice: null,
                        regulationPrice: null,
                        taxRules: [],
                        taxStatus: 'net',
                        unitPrice: 0,
                    },
                    deliveries: [],
                    apiAlias: 'cart',
                },
                specials: {
                    preparedCoupon: null,
                    shippingMethodId: null,
                },
                shippingMethods: null,
                methods: {
                    setLoadingShipping(loading) {
                        debugLog('setLoading shipping', loading);
                        set({ loadingShipping: loading });
                    },
                    setCart(cart: Cart): void {
                        set(() => {
                                const { getShippingMethods } = get().methods;


                                const keys = Object.keys(cart.errors || {});

                                if (keys.filter((key) => key.includes('shipping-method-changed')).length) {
                                    getShippingMethods();
                                    return ({ cart });
                                }

                                return ({ cart });
                            },
                        );

                        get().methods.autoApplyPreparedCoupon();
                    },
                    autoApplyPreparedCoupon() {
                        const methods = get().methods;
                        const applied = methods.getAppliedCouponCodes();
                        const code = methods.getPreparedCoupon();

                        if (Array.isArray(applied)
                            && typeof code === 'string'
                            && !applied.includes(code)
                        ) {
                            const email = SessionStore?.getState()?.methods?.getEclubData()?.email || null;
                            methods.applyCoupon(code, email).then(() => null);
                        }
                    },
                    getPreparedCoupon(): string | null {
                        return get()?.specials?.preparedCoupon || null;
                    },
                    setPreparedCoupon(coupon) {
                        set((state) => ({
                            specials: {
                                ...state.specials,
                                preparedCoupon: coupon,
                            },
                        }));
                    },
                    setToken(token) {
                        set({ token });
                        this.refresh();
                    },
                    refresh() {
                        const { getCart } = get().methods;
                        getCart();
                    },
                    setIsLoading(bool = false, logMessage = 'no message') {
                        debugLog('set loading cart:', logMessage, bool, 'shipping state:', get().loadingShipping);
                        set({ loading: bool });
                    },
                    async getCart(isCheckout: boolean = false) {
                        const {
                            setIsLoading,
                            setCart,
                            autoApplyPreparedCoupon,
                        } = get().methods;

                        let headers: CartHttpHeaders = {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        };

                        if (isCheckout) {
                            headers['sw-is-checkout'] = true;
                        }

                        setIsLoading(true, 'running getCart');

                        const response = await fetch('/api/cart/get', {
                            method: 'get',
                            headers: headers,
                            credentials: 'include',
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            setCart(result.cart);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                            throw new Error(
                                handleErrors(
                                    result,
                                    'error',
                                    'An unknown error occurred while attempting to fetch your cart.',
                                ),
                            );
                        }

                        autoApplyPreparedCoupon();

                        debugLog('ended getCart');
                    },
                    // @ts-ignore
                    async applyCoupon(code: string, email: string | null = '') {
                        const {
                            setCart,
                            trackAddedCouponCodes,
                            setPreparedCoupon,
                            getPreparedCoupon,
                        } = get().methods;

                        const response = await fetch('/api/cart/applyCoupon', {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                code: code.toUpperCase(),
                                email: email || '',
                            }),
                        });

                        const result: {cart: Cart, status: string} = await response.json();
                        if (result.status === 'success') {
                            let errorKeys = Object.keys(result.cart.errors || {});

                            if (result.cart.errors && errorKeys.indexOf('promotion-not-found') >= 0) {
                                if (getPreparedCoupon() === code) {
                                    setPreparedCoupon(null);
                                    return result.cart.errors;
                                }
                            }

                            Object.values(result.cart.errors || {}).forEach((item) => {
                                const app = ApplicationStore?.getState()?.methods;
                                if (item.messageKey === 'promotion-discount-added') {
                                    app.notify('errors:discount-code-success', {
                                        title: 'your-cart',
                                        messageData: { code },
                                    });
                                    setPreparedCoupon(null);
                                }
                            });

                            if (result.cart) {
                                if (result?.cart?.lineItems?.length === 0) {
                                    setPreparedCoupon(code);
                                }

                                setCart(result.cart);
                                trackAddedCouponCodes(result.cart).then(() => null);

                                return result.cart.errors;
                            }
                        } else {
                            if (result.status === 'missing-email') {
                                return {
                                    'email-required': {
                                        key: 'email-required',
                                        level: 5,
                                    },
                                };
                            } else if (result.status === 'promotion-not-found' || result.status === 'promotion-redeemed') {
                                return result.cart.errors;
                            } else {
                                throw new Error(
                                    handleErrors(
                                        result,
                                        'error',
                                        'An unknown error occurred while attempting to apply coupon code.',
                                    ),
                                );
                            }
                        }
                    },
                    getAddedProductNumber(productId) {
                        let addedProductNumber = '';
                        get()?.cart?.lineItems?.forEach((item) => {
                            if (item.referencedId === productId) {
                                addedProductNumber = item.payload.productNumber;
                            }
                        });

                        return addedProductNumber;
                    },
                    getAppliedCouponCodes() {
                        return get().cart?.extensions?.['cart-promotions']?.addedCodes;
                    },
                    async addToCart({ product, quantity }) {
                        const {
                            getItemInCart,
                            updateCart,
                            getAddedProductNumber,
                            setCart,
                            setIsLoading,
                        } = get().methods;

                        let cartItem = getItemInCart(product);

                        if (cartItem) {
                            await updateCart({ item: cartItem.id, quantity: cartItem.quantity + quantity });
                        } else {
                            setIsLoading(true, 'running addToCart');

                            const response = await fetch('/api/cart/add', {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                credentials: 'include',
                                body: JSON.stringify({
                                    product,
                                    quantity: parseInt(quantity.toString()),
                                }),
                            });

                            const result = await response.json();

                            if (result.status === 'success') {
                                setCart(result.cart);

                                let productNumber = getAddedProductNumber(product.id);

                                // Trigger event listeners to show header and open cart
                                document.dispatchEvent(new CustomEvent('add-to-cart', {
                                    detail: productNumber,
                                }));

                                setIsLoading(false);
                            } else {
                                setIsLoading(false);
                                throw new Error(
                                    handleErrors(
                                        result,
                                        'error',
                                        'An unknown error occurred while attempting to add the item to your cart.',
                                    ),
                                );
                            }
                        }
                    },
                    async addItemsToCart(productIds: string[]) {
                        const { setCart, getCart, setIsLoading } = get().methods;
                        const api = new StorefrontApiService();

                        await getCart();
                        const existingIds = get()?.cart?.lineItems?.map((item) => item.referencedId);
                        const ids = productIds?.filter((id) => {
                            return !existingIds?.includes(id);
                        });

                        setIsLoading(true, 'running addItemsToCart');

                        if (ids.length >= 1) {
                            document.dispatchEvent(new CustomEvent('add-to-cart', {}));

                            try {
                                const response = await api.addItemsToCart(ids);

                                if (response.status === 'success' && response?.cart) {
                                    setCart(response.cart);
                                }

                            } catch (e) {
                                console.error('[CartFailure]', e);
                            }
                        }

                        setIsLoading(false);
                    },
                    referenceIdExistInCart(referenceId: ShopwareUuid | string): boolean {
                        let exists = false;

                        get().cart.lineItems?.forEach((item) => {
                            if (item.referencedId === referenceId) {
                                exists = true;
                            }
                        });

                        return exists;
                    },
                    getCartDiscount() {
                        let items = get().cart.lineItems;

                        if (!items?.length) {
                            return 0;
                        }

                        let discountTotal = 0;
                        items.forEach((item) => {
                            const price = item.price;
                            const qty = item.quantity;


                            // @ts-ignore
                            if (item?.payload?.npPrices?.listPrice) {
                                // @ts-ignore @todo fix
                                discountTotal += qty * Math.abs(item?.payload?.npPrices?.listPrice - item?.payload?.npPrices?.price);
                            } else if (price?.listPrice) {
                                // @ts-ignore
                                discountTotal += qty * Math.abs(price.listPrice.discount);
                            }
                        });
                        return discountTotal;
                    },
                    getCartQuantity() {
                        let quantity = 0;

                        get()?.cart?.lineItems?.forEach((item) => {
                            if (item.type === 'product' && Object.keys(expressShippingSkus).indexOf(item.payload?.productNumber) < 0) {
                                quantity += item.quantity;
                            }
                        });

                        return quantity;
                    },
                    async applyGiftcard(code): Promise<Cart> {
                        const { getCart } = get().methods;
                        const response = await fetch('/api/cart/applyGiftcard', {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                code: code,
                            }),
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            await getCart();
                            return result.data;
                        } else {
                            return result.data;
                        }
                    },
                    async removeGiftcard(code: string): Promise<any> {
                        const { getCart } = get().methods;
                        const response = await fetch('/api/cart/applyGiftcard', {
                            method: 'delete',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                code: code,
                            }),
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            await getCart();
                            return result.data;
                        } else {
                            return result.data;
                        }
                    },
                    async updateCart({ item, quantity, isCheckout = false }) {
                        const { setIsLoading, setCart } = get().methods;
                        let headers: CartHttpHeaders = {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        };

                        if (isCheckout) {
                            headers['sw-is-checkout'] = true;
                        }

                        setIsLoading(true, 'running updateCart');

                        const response = await fetch('/api/cart/update', {
                            method: 'post',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({
                                item,
                                quantity: parseInt(String(quantity)),
                            }),
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            setCart(result.cart);
                            setIsLoading(false);

                            // Trigger event listeners to track cart updates
                            document.dispatchEvent(new CustomEvent('has-updated-cart'));
                        } else {
                            setIsLoading(false);
                            throw new Error(
                                handleErrors(
                                    result,
                                    'error',
                                    'An unknown error occurred while attempting to add the item to your cart.',
                                ),
                            );
                        }
                    },
                    async removeCartItem({ item, isCheckout = false, code = false }) {
                        const {
                            setIsLoading,
                            setCart,
                            trackAddedCouponCodes,
                        } = get().methods;
                        let headers: CartHttpHeaders = {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        };

                        if (isCheckout) {
                            headers['sw-is-checkout'] = true;
                        }

                        setIsLoading(true, 'running removeCartItem');

                        const response = await fetch('/api/cart/remove', {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                item,
                            }),
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            setCart(result.cart);
                            setIsLoading(false);

                            if (code) {
                                trackAddedCouponCodes(result.cart).then(() => null);
                            }

                            setIsLoading(false);

                            // Trigger event listeners to track cart item removals
                            document.dispatchEvent(new CustomEvent('remove-from-cart'));
                        } else {
                            setIsLoading(false);
                            throw new Error(
                                handleErrors(
                                    result,
                                    'error',
                                    'An unknown error occurred while attempting to add the item to your cart.',
                                ),
                            );
                        }
                    },
                    async trackAddedCouponCodes(): Promise<void> {
                        const { getAppliedCouponCodes } = get().methods;
                        let appliedCoupons = getAppliedCouponCodes();

                        await fetch('/api/tracking/custom-fields', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({ customFields: { applied_coupons: appliedCoupons } }),
                        });
                    },
                    getItemInCart(referenceId: string): LineItem | null {
                        let foundItem = null;

                        get()?.cart?.lineItems?.forEach((item: LineItem) => {
                            if (item.referencedId === referenceId) {
                                foundItem = item;
                            }
                        });

                        return foundItem;
                    },
                    async getShippingMethods() {
                        let getShippingMethodsEndpoint = '/api/checkout/shipping-methods';

                        try {
                            let requestData = await fetch(getShippingMethodsEndpoint, {
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });

                            let shippingMethodsData = await requestData.json();

                            shippingMethodsData.sort((a: any, b: any) => {
                                if (a.position > b.position) {
                                    return 1;
                                } else if (a.position < b.position) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            });

                            set({ shippingMethods: shippingMethodsData });
                        } catch (e) {
                            console.error(e);
                        }
                    },
                    async setShippingMethod(shippingMethodId: ShopwareUuid) {
                        setShippingMethodDebounced(shippingMethodId);
                    },
                    getShippingMethodId(): ShopwareUuid | string | null {
                        return get().methods.getCurrentShippingMethod()?.id || null;
                    },
                    getCurrentShippingMethod(): ShippingMethod | null {
                        return get().cart?.deliveries?.[0]?.shippingMethod || null;
                    },
                },
            });
        },
        {
            name: 'cart-storage',
            partialize: (state) => ({
                token: state.token,
                shipping: state.shipping,
                cart: state.cart,
                specials: state.specials,
            }),
        },
    ),
);

export default useCartStore;
