import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import StorefrontApiService from '@services/StorefrontApiService';

// Define types
interface Notification {
    id: string;
    type?: string;
    message?: string | null;
    removing: boolean;
    timestamp: Date;
    clearTimeoutId: ReturnType<typeof setTimeout>;
    title?: string | null;
    titleData?: object | null;
    messageData?: object | null;
    components?: JSX.Element;
    onClick?: () => void,
    [key: string]: any;
}

interface ApplicationState {
    marketingLabels: Record<string, string>;
    surrogateKeys: string[];
    notifications: Notification[];
    modals: {
        accountDrawerOpen: boolean,
        wishlistDrawerOpen: boolean,
    },
    methods: {
        isDevelopment: () => boolean;
        warmupStaticProperties: () => Promise<void>;
        setSurrogateKeys: (keys: string[]) => void;
        getMarketingLabels: (propertyIds: string[]) => string[];
        removeNotification: (id: string, delay?: number) => ReturnType<typeof setTimeout>;
        notify: (msg: string | null, options?: Partial<Notification>) => void;
        toggleDrawer: <K extends keyof ApplicationState['modals']>(key: K, open?: boolean) => void;
    };
}

const api = new StorefrontApiService();

const useApplicationStore = create<ApplicationState>()(
    persist(
        (set, get) => ({
            marketingLabels: {},
            surrogateKeys: [],
            notifications: [],
            modals: {
                accountDrawerOpen: false,
                wishlistDrawerOpen: false,
            },
            methods: {
                toggleDrawer(key, open) {
                    set((state) => {
                        let value = !state.modals[key];
                        if (open !== undefined) {
                            value = open;
                        }
                        const modals = {
                            ...state.modals,
                            [key]: value,
                        };
                        return { modals };
                    });
                },
                isDevelopment() {
                    return ['localhost', 'stage', '.int', '.local'].some(term => location.href.includes(term));
                },
                async warmupStaticProperties() {
                    const labels = await api.fetchMarketingLabels();
                    set({ marketingLabels: labels.marketingLabels });
                },
                setSurrogateKeys(keys) {
                    set({ surrogateKeys: keys });
                },
                getMarketingLabels(propertyIds) {
                    const labels = get().marketingLabels;
                    return propertyIds?.filter((id) => labels?.[id]).map((id) => {
                        return labels[id];
                    }) || [];
                },
                removeNotification(id, delay = 0) {
                    return setTimeout(function() {
                        set({
                            notifications: get().notifications.map(n => {
                                if (n.id === id) {
                                    n.removing = true;
                                }
                                return n;
                            }),
                        });

                        setTimeout(() => {
                            set({ notifications: get().notifications.filter(n => n.id !== id) });
                        }, 500);
                    }, delay);
                },
                notify(msg, options: Partial<Notification> = {}) {
                    const state = get();
                    const id = options?.id ? options.id : btoa((new Date()).getTime().toString());
                    const message = {
                        type: 'standard',
                        ...options,
                        id,
                        message: msg,
                        removing: false,
                        timestamp: new Date(),
                        clearTimeoutId: state.methods.removeNotification(id, 7000),
                    };

                    set({ notifications: [message, ...get().notifications] });
                },
            },
        }),
        {
            name: 'app-storage',
            partialize: (state) => ({
                marketingLabels: state.marketingLabels,
            }),
        },
    ),
);

export default useApplicationStore;
