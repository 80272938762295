import { makeStyles } from '@material-ui/styles';
import ProductThumbnail from '@components/products/thumbnail';
import If from '@components/misc/If';

const useStyles = makeStyles({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '50px 1fr',
        gap: 10,
        borderRadius: 4,
        marginTop: 8,
        padding: '4px',
        width: '100%',
    },
    brand: {
        fontSize: 12,
        fontFamily: 'Chronicle, "Open Sans"',
        lineHeight: 1.8,
        marginBottom: 8,
    },
    children: {
        display: 'flex',
        width: '100%',
    },
}, { name: 'ProductNotify' });

export default function ProductNotify({product, children}) {
    const classes = useStyles();

    if (!product) {
        return null;
    }

    return (
        <>
            <div className={classes.wrapper}>
                <ProductThumbnail image={product.cover} alt={product.translated?.name} size={550} />
                <section className={classes.product}>
                    <span
                        className={classes.brand}>{product?.manufacturer?.translated?.name?.toUpperCase()}</span><br />
                    <span className={classes.name}>{product?.translated?.name}</span>
                </section>
            </div>
            <If condition={children}>
                <section className={classes.children}>
                    {children}
                </section>
            </If>
        </>
    );
}
