import Image from "react-storefront/Image";
import {makeStyles} from "@material-ui/styles";
import theme from "../theme";

const useStyles = makeStyles(() => ({
	image__wrapper: {
		flexGrow: 1,
		backgroundColor: theme.palette.primary.background,
		[theme.breakpoints.up('md')]: {
			'& img': {
				transition: 'opacity 0.25s ease'
			},
			'&:hover img': {
				opacity: 0.9
			}
		}
	}
}), {name: 'ProductThumbnail'});

export default function ProductThumbnail({image, size = 550, index = 10, alt = 'Product Name'}) {
	const classes = useStyles();

	function getThumbnailImageUrl() {
		const coverImage = image ? (image.media ? image.media : image) : [];

		if(coverImage.metaData && coverImage.metaData.width < 1200) {
			if (coverImage.metaData.width > 550) {
				console.info('Cover image is oversized size:', coverImage.metaData);
			}

			return {
				src: coverImage.url,
				width: coverImage.metaData.width,
				height: coverImage.metaData.height,
				aspectRatio: coverImage.metaData.height / coverImage.metaData.width
			}
		}

		let thumbnail = getAppropriateImage(coverImage.thumbnails);

		return {
			src: thumbnail.url,
			width: thumbnail.width,
			height: thumbnail.height,
			aspectRatio: thumbnail.height / thumbnail.width
		}
	}

	function getAppropriateImage(thumbnails) {
		let sizeDiff = {diff: 0, index: false};
		let image = false;

		if(thumbnails) {
			thumbnails.forEach((thumb, index) => {
				if (thumb.width === size) {
					image = thumb;
				} else {
					let currSizeDiff = Math.abs(thumb.width - size);

					if (sizeDiff.diff === 0 || currSizeDiff < sizeDiff) {
						sizeDiff = {diff: currSizeDiff, index: index};
					}
				}
			});
		}

		if (!image) {
			if(sizeDiff.diff > 0) {
				let firstImage = thumbnails[sizeDiff.index]
				image = {url: firstImage.url, width: firstImage.width, height: firstImage.height};
			} else {
				image = {url: '/images/placeholder/product.jpg', width: 550, height: 510};
			}
		}

		return image;
	}

	return (
		<Image {...getThumbnailImageUrl()}
			alt={alt}
			contain
			lazy={index > 7 ? true : 'ssr'}
			lazyOffset={400}
			notFoundSrc={'/images/placeholder/product.jpg'}
			className={classes.image__wrapper}
		/>
	)
}
